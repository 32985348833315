<div class="logo">
	<a href="/" title="Inlights Logo">
		<img class="logo" src="/assets/img/inlights-light.svg" alt="Inlights Logo" />
	</a>
</div>

<div class="sidebar-wrapper">

	<div *ngIf="isMobileMenu()">
		<ul class="nav navbar-nav nav-mobile-menu" app-navbar-items>

		</ul>
	</div>

	<ul class="nav">
		@if((clientId$ | async) === null) {
			<ng-container *ngTemplateOutlet="itemtemplate; context: { $implicit: nonClientMenu$ | async }"></ng-container>
		<!-- @for( menuItem of nonClientMenu$ | async; track $index){
		<li routerLinkActive="active" class="{{menuItem.class}} nav-item">
			<a class="nav-link" [routerLink]="menuItem.path">
				@if(menuItem.svgIcon) {
				<mat-icon [svgIcon]="menuItem.icon!"></mat-icon>
				} @else {
				<mat-icon fontSet="material-symbols-outlined">{{menuItem.icon}}</mat-icon>
				}
				<p>{{menuItem.title}}</p>
			</a>
		</li>
		} -->
		<li>
			} @else {
		<li>
			<a class="nav-link exit-client" [routerLink]="['/']"><mat-icon>exit_to_app</mat-icon>
				<p> Exit Client Area</p>
			</a>
		</li>
		}
		<li>
			<app-client-selector />
		</li>
		@if(clientMenu$ | async; as clientMenu) {
		<ng-container *ngTemplateOutlet="itemtemplate; context: { $implicit: clientMenu }"></ng-container>
		}
	</ul>
</div>

<ng-template ngFor let-menuItems #itemtemplate>
	@for( menuItem of menuItems; track $index){
	<li routerLinkActive="active" class="{{menuItem.icon}} nav-item">
		<a class="nav-link" [routerLink]="menuItem.route">
			@if(menuItem.svgIcon) {
			<mat-icon [svgIcon]="menuItem.icon"></mat-icon>
			} @else {
			<mat-icon fontSet="material-symbols-outlined">{{menuItem.icon}}</mat-icon>
			}
			<p>{{menuItem.title}}</p>
		</a>
		@if(menuItem.children) {
		<ul class="sub-nav">
			<ng-container *ngTemplateOutlet="itemtemplate; context: { $implicit: menuItem.children }"></ng-container>
		</ul>
		}
	</li>
	}
</ng-template>
