import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { prefersDarkMode$, PresistedBehaviorSubject } from '@shared/rxjs';
import { combineLatest, map } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ThemeService {
	private renderer: Renderer2;

	public static readonly themes = ['dark-m2', 'light-m2', 'dark-m3', 'light-m3'];
	public static readonly mappings: Record<string, string> = {
		'dark-theme': 'dark-m3',
		'light-theme': 'light-m3'
	};

	private readonly darkTheme = 'dark-m3';
	private readonly lightTheme = 'light-m3';
	private styleLink: HTMLLinkElement;

	public savedTheme$ = new PresistedBehaviorSubject<string | null>('main-theme', null);
	private systemTheme$ = prefersDarkMode$.pipe(map(prefersDarkMode => prefersDarkMode ? this.darkTheme : this.lightTheme));
	public currentTheme$ = combineLatest([this.savedTheme$, this.systemTheme$]).pipe(
		map(([theme, prefersDarkMode]) => theme ?? prefersDarkMode)
	)

	public constructor(
		rendererFactory: RendererFactory2,
		private sanitizer: DomSanitizer
	) {
		this.renderer = rendererFactory.createRenderer(null, null);

		this.styleLink = this.renderer.createElement('link');
		this.renderer.setAttribute(this.styleLink, 'rel', 'stylesheet');
		this.renderer.setAttribute(this.styleLink, 'type', 'text/css');
		this.renderer.appendChild(document.head, this.styleLink);

		this.currentTheme$.subscribe(t => this.applyTheme(t));

	}

	public setTheme(theme: string | null): void {
		this.savedTheme$.next(theme);
	}

	private applyTheme(theme: string): void {
		theme = ThemeService.mappings[theme] ?? theme;
		const htmlElement = document.documentElement;
		ThemeService.themes.forEach(t => {
			this.renderer.removeClass(htmlElement, t);
		});
		this.renderer.addClass(htmlElement, theme);
		this.renderer.setAttribute(this.styleLink, 'href', `${theme}.css`);
	}
}

