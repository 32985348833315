import { AuthGuard as authenticationGuard } from '@auth0/auth0-angular';
import { InlightsRoute } from '@shared/models/shell/InlightsRoute';

export const userManagementRoutes: InlightsRoute[] = [
	{
		title: 'User Management',
		icon: 'people',
		showInMenu: true,
		path: 'user-management',
		loadComponent: () =>
			import('./pages/user-management-index/user-management-index.component').then(
				(m) => m.UserManagementIndexComponent,
			),
		canActivate: [authenticationGuard],
		data: {
			requiredClaims: [],
			allowAnonymousAccess: false,
		},
		children: [
			{
				title: 'User Details',
				showInMenu: false,
				path: 'details/:id',
				loadComponent: () =>
					import('./pages/user-management-details/user-management-details.component').then(
						(m) => m.UserManagementDetailsComponent,
					),
			}
		]
	},
];
