import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { InlightsRoute, InlightsRouteData } from '@shared/models/shell/InlightsRoute';
import { clientRoutes, nonClientRoutes } from 'app/app.routes';
import { combineLatest, filter, map, shareReplay } from 'rxjs';
import { AuthorisationService, PermissiosType } from '../authorisation/authorisation.service';
import { MenuItem } from './MenuItem';

@Injectable({
	providedIn: 'root'
})
export class NavigationService {


	constructor(private authorisationService: AuthorisationService, private router: Router, private activatedRoute: ActivatedRoute) {

		combineLatest([this.authorisationService.areas$, this.router.events]).pipe(
			filter(([, event]) => event instanceof NavigationEnd),
			map(([areas,]) => this.createActivatedMenu(areas, this.activatedRoute)),
		).subscribe();
	}

	private createActivatedMenu(areas: { [key: string]: PermissiosType; }, route: ActivatedRoute): MenuItem[] {
		let childRoutes: InlightsRoute[] = [];
		let currentRoute = route;
		while (currentRoute.firstChild) {
			currentRoute = currentRoute.firstChild;
		}
		if (currentRoute.routeConfig && currentRoute.routeConfig.children) {
			childRoutes = currentRoute.routeConfig.children as InlightsRoute[];
		}
		const children = this.createMenuItems(areas, childRoutes);
		return [new MenuItem('Home', '', 'home', undefined, children)];
	}

	private createMenuItems(areas: { [key: string]: PermissiosType; }, routes: InlightsRoute[]): MenuItem[] {
		const menuItems = new Array<MenuItem>();
		for (const route of routes) {
			if (!this.isPermitted(areas, route)) continue;
			const menuItem = new MenuItem(route.title!, route.path!, route.icon, route.svgIcon);
			if (route.children) {
				menuItem.children = this.createMenuItems(areas, route.children!);
			}
			menuItems.push(menuItem);
		}
		return menuItems;
	}

	public clientMenu$ = this.authorisationService.areas$.pipe(
		map((areas) => this.getPermittedItems(clientRoutes, areas)),
		shareReplay(1)
	);

	public nonClientMenu$ = this.authorisationService.areas$.pipe(
		map((areas) => this.getPermittedItems(nonClientRoutes, areas)),
		shareReplay(1)
	);

	private getPermittedItems(routes: InlightsRoute[], area: { [key: string]: PermissiosType; }): MenuItem[] {

		const menuItems = new Array<MenuItem>();

		for (const route of routes) {
			if (!this.isPermitted(area, route)) continue;
			const menuItem = new MenuItem(route.title!, route.path!, route.icon, route.svgIcon);
			if (route.children) {
				menuItem.children = this.getPermittedItems(route.children!, area);
			}
			menuItems.push(menuItem);
		}
		return menuItems;
	}

	private isPermitted(areas: { [key: string]: PermissiosType; }, route: InlightsRoute) {
		if (!route.showInMenu) return false;
		const routeData = route.data as InlightsRouteData;
		if (routeData.requiredClaims.length === 0) return true;

		return routeData.requiredClaims.some((claim) => {
			const permissions = areas[claim.name];
			return permissions && claim.value.some((permission) => permissions[permission]);
		});
	}
}
