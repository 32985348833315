import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';
import { BaseComponent } from '@shared/components';
import { GlobalClientService } from '@shared/services';
import { NavbarComponent } from 'app/components/navbar/navbar.component';
import { SidebarComponent } from 'app/components/sidebar/sidebar.component';
import { map, tap } from 'rxjs';
import { NewVersionNotifierComponent } from '../new-version-notifier/new-version-notifier.component';

@Component({
	selector: 'app-client-context',
	standalone: true,
	imports: [CommonModule,
		RouterOutlet,
		RouterLink,
		NewVersionNotifierComponent,
		NavbarComponent,
		SidebarComponent,
	],
	templateUrl: './client-context.component.html',
	styleUrl: './client-context.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientContextComponent extends BaseComponent {
	public isSideBarExpanded = true;
	public toggleSideBar() {
		this.isSideBarExpanded = !this.isSideBarExpanded;
	}

	public selectedClient$ = this.globalClientService.selectedClient$;

	constructor(private route: ActivatedRoute, private globalClientService: GlobalClientService) {
		super(route);

		this.route.params.pipe(
			this.takeUntilDestroyed(),
			map(params => params['clientID']),
			tap(clientID => this.globalClientService.setClient(clientID))
		).subscribe();
	}
}
